<template>
    <zw-sidebar @shown="shown" title="Papierform wählen">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col cols="6">
                <b-form-group label="Ansprache:">
                    <b-form-select v-model="form.speech" :options="speechOptions"></b-form-select>
                </b-form-group>
            </b-col>
            <b-col v-if="spechFormat">
                <b-form-group label="Anspracheform:">
                    <strong>{{ spechFormat }}</strong>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group label="Header:">
                    <b-form-select v-model="headerId"
                                   :options="headerOptions"
                                   class="mb-3"
                                   @change="headerSelected"
                    ></b-form-select>

                    <zw-ckeditor v-model="form.header"></zw-ckeditor>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="Footer:">
                    <b-form-select v-model="footerId"
                                   :options="footerOptions"
                                   class="mb-3"
                                   @change="footerSelected"
                    ></b-form-select>

                    <zw-ckeditor v-model="form.footer"></zw-ckeditor>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="6">
                <b-form-group label="Adressauswahl:">
                    <b-form-select v-model="form.address_type" :options="addressTypeOptions"></b-form-select>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col sm="6">
            </b-col>
            <b-col sm="6" class="text-sm-right text-xs-center">
                <b-row>
                    <b-col sm="6">
                        <b-button block variant="primary" @click="submit('papiernormal')" class="mr-5">Normales Papier
                        </b-button>
                    </b-col>
                    <b-col sm="6">
                        <b-button block variant="info" @click="submit('papierbrief')" class="mr-5">Briefpapier
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    data() {
        return {
            loading: true,
            payload: {},
            headerId: null,
            footerId: null,
            form: {
                speech: 'none',
                header: null,
                footer: null,
                address_type: 'billing'
            },
            speechOptions: [
                {value: 'foermlich', text: 'Förmlich'},
                {value: 'persoenlich', text: 'Persönlich'},
                {value: 'none', text: 'Keine'},
            ],
            headerOptions: [{value: null, text: 'Empty'}],
            footerOptions: [{value: null, text: 'Empty'}],
            addressTypeOptions: [
                {value: 'billing', text: 'Rechnungsadresse'},
                {value: 'shipping', text: 'Versandadresse'},
            ]
        }
    },
    computed: {
        spechFormat: function () {
            if (this.form.speech == 'foermlich') {
                return 'Sehr geehrte Nachname'
            } else if (this.form.speech == 'persoenlich') {
                return 'Sehr geehrte Vorname Nachname'
            }

            return ''
        },
    },
    methods: {
        ...mapGetters('Templates', ['getFooters', 'getHeaders']),
        shown() {
            this.$store.dispatch('Templates/fetchTemplates', this.payload.type)
                .then(() => {
                    this.getHeaders().forEach(header => {
                        this.headerOptions.push({value: header.id, text: header.titel})
                        if (header.is_default) {
                            this.headerId = header.id
                            this.form.header = header.header
                        }
                    })

                    this.getFooters().forEach(footer => {
                        this.footerOptions.push({value: footer.id, text: footer.titel})
                        if (footer.is_default) {
                            this.footerId = footer.id
                            this.form.footer = footer.footer``
                        }
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        headerSelected(value) {
            let header = this.getHeaders().filter(header => header.id == value)[0] ?? null

            this.form.header = header ? header.header : ''
        },
        footerSelected(value) {
            let footer = this.getFooters().filter(footer => footer.id == value)[0] ?? null

            this.form.footer = footer ? footer.footer : ''
        },
        submit(paper) {
            let postData = {
                header: this.form.header,
                footer: this.form.footer,
                doctype: this.payload.type,
                style: this.form.speech,
                address: this.form.address_type,
            }

            const formData = new FormData()
            Object.keys(postData).forEach((key) => {
                formData.append(key, postData[key])
            })
            formData.append('kvaIds[]', this.payload.selected)

            this.loading = true
            window.axios.post('/sales/index/generate-invoices', formData, {'skip_loading': true})
                .then(response => {
                    if (response.data.kva_ids.length) {
                        $("<form id='downloadFormPoster' action='/sales/index/gesamtinvoices/' method='post' target='_blank'>" +
                            "<input type='hidden' name='doc_type' value='" + this.payload.type + "'/>" +
                            "<input type='hidden' name='kvaIds' value='" + JSON.stringify(response.data.kva_ids) + "'/>" +
                            "</form>")
                            .appendTo("body")
                            .submit();

                        this.$root.$children[0].closeModal(this.$children[0].$refs['sidebar'])
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>